import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "time", "output" ]

  connect() {
    this.countdown()
    this.startCountdown()
  }

  startCountdown(){
    setInterval(() => {
      this.countdown()
    }, 1000)
  }

  countdown(){
    var countDownDate = new Date(this.timeTarget.value).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = days*24 + Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 0) {
      this.outputTarget.textContent = "Čas potekel"
    }
    else{
      this.outputTarget.textContent = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0')
    }
  }
}
